import React, { useState } from 'react'
import { firestore } from "../../firebase.js"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import styled from "styled-components"

// Картинки
import comment from "../images/icons/comment.svg"
import upload from "../images/icons/photo.svg"
import close from "../images/icons/close.svg"
import closeWhite from "../images/icons/close-white.svg"

const CommentsForm = styled("div")`
  z-index: 999;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 30px;
  overflow: auto;
  display: flex;
  input,textarea {
    outline: 0;
    border-radius: 15px;
    padding: 10px;
    // font-size: 14px;
    width: 100%;
    border: 1px solid #ececec;
    // &::placeholder {
    //   font-size: 12px;
    // }
    &.error {
      border: 1px solid red;
      &::placeholder {
        color: red;
      }
    }
  }
  input[type="file"] {
    padding: 0;
    border-radius: 0;
    border: 0;
  }
  .send-review {
    background-color: #4a90e2;
    height: 45px;
    border-radius: 30px;
    // font-size: 14px;
    color: #ffffff;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s;
    width: 100%;
    margin-left: auto;
    &:hover {
      box-shadow: rgba(30, 48, 110, 0.3) 0px 10px 26px 0px;
      background-color: rgb(49, 99, 156);
    }
  }
  .popup-container {
    position: relative;
    margin: auto;
    background: white;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    width: 30%;
    display: grid;
    text-align: center;
    gap: 10px;

    @media(min-width:768px) {
      min-width: 500px;
      max-width: 500px;
    }

    @media(max-width:767px) {
      width: 100%;
    }
  }
  .close-popup {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }
  .upload-img {
    padding: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 15px;
    background: rgb(236, 236, 236);

    input {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }

    img {
      margin: 0;
    }
  }
  .image-product {
    margin: -20px auto;
  }
`;

const ReviewButton = styled("button")`
  background-color: #4a90e2;
  height: 45px;
  border-radius: 30px;
  font-size: 14px;
  color: #ffffff;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  width: fit-content;
  &:hover {
    box-shadow: rgba(30, 48, 110, 0.3) 0px 10px 26px 0px;
    background-color: rgb(49, 99, 156);
  }
`

const Rating = styled("div")`
  // border-radius: 15px;
  // padding: 10px;
  // border: 1px solid rgb(236, 236, 236);

  .star-rating {
    display: flex;
    cursor: pointer;
  }

  .star-rating .star,
  .star-rating .star:hover ~ .star {
    font-size: 24px;
    color: #d9d9d9;
    transition: color 0.2s;
    margin-right: 5px;
  }

  .star-rating:hover .star,
  .star-rating .star.active {
    color: #4a90e2;
  }

  p {
    transition: all .3s;
    @media(max-width:767px){
      text-align: left;
    }
    &.active {
      color: rgb(74, 144, 226);
      font-weight: 600;
    }
  }
`;

const ReviewForm = ({ pageIdentifier, nameProduct, imgProduct, brand, reviewsLength }) => {
	const [commentData, setCommentData] = useState({
    name: '',
    text: '',
    file: null,
	});
	const [showPopup, setShowPopup] = useState(false);
  const [showPopupForm, setShowPopupForm] = useState(false);
  const [starRating, setStarRating] = useState({
    motionIsolation: 0,
    easeMovement: 0,
    edgeSupport: 0,
    temperatureControl: 0,
    overallScore: 0,
  });
  const [errorForm, serErrorForm] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

	const handleCommentSubmit = async () => {
    if (commentData.text.trim() !== '' && commentData.name.trim() !== '' && starRating.motionIsolation !== 0 && starRating.easeMovement !== 0 && starRating.edgeSupport !== 0 && starRating.temperatureControl !== 0 && starRating.overallScore !== 0) {
      const storage = getStorage();
      const commentsRef = collection(firestore, 'reviews');
      const storageRef = ref(storage, 'commentImages');

      let imageUrl = null;
      let imageFileName = null;

      if (commentData.file) {
        imageFileName = `${Date.now()}_${commentData.file.name}`;
        const imageRef = ref(storageRef, imageFileName);
        await uploadBytes(imageRef, commentData.file);
        imageUrl = await getDownloadURL(imageRef);
      }

      const data = {
        ...commentData,
        pageIdentifier: pageIdentifier,
        timestamp: serverTimestamp(),
        file: imageUrl,
        fileName: imageFileName,
        motionIsolation: starRating.motionIsolation,
        easeMovement: starRating.easeMovement,
        edgeSupport: starRating.edgeSupport,
        temperatureControl: starRating.temperatureControl,
        overallScore: starRating.overallScore,
        show: false,
      };

      // Add the comment data to Firestore
      await addDoc(commentsRef, data);

      // Reset the form and state
      setCommentData({
        name: '',
        text: '',
        file: null,
      });

      setStarRating({
        motionIsolation: 0,
        easeMovement: 0,
        edgeSupport: 0,
        temperatureControl: 0,
        overallScore: 0,
      });

      setShowPopup(true);
    } else {
      serErrorForm(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
  	if (type === 'file') {
	    const file = e.target.files[0];
	    setCommentData({
	      	...commentData,
	      	file,
	    });

      // Read the image and set the URL
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedImageUrl(event.target.result);
      };
      reader.readAsDataURL(file);
  	} else {
    	setCommentData({
     		...commentData,
      		[name]: value,
    	});
  	}
	};

  const handleTextAreaChange = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto'; // Reset the height to auto to recalculate the scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
    setCommentData({
      ...commentData,
      text: textarea.value,
    });
  };

  const openPopup = () => {
    setShowPopupForm(true);
    document.body.classList.add('overflow-hidden');
  };

  const closePopup = () => {
    setShowPopupForm(false);
    document.body.classList.remove('overflow-hidden');
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('overlay')) {
      closePopup();
    }
  };

  const handleStarClick = (e, criteria) => {
    const starIndex = Array.from(e.currentTarget.children).indexOf(e.target);
    const newStarRating = { ...starRating };
    newStarRating[criteria] = starIndex === 5 ? 0 : starIndex + 1;
    setStarRating(newStarRating);
  };

  const handleRemoveFile = () => {
    setCommentData({
      ...commentData,
      file: null,
    });
    setUploadedImageUrl(null);
  };

  return (
    <>
      <ReviewButton onClick={openPopup} className={reviewsLength}>Оставить отзыв</ReviewButton>
      {showPopupForm && (
        <CommentsForm onClick={handleOverlayClick} className="overlay">
          <div className="popup-container">
            <button className="close-popup" onClick={closePopup}><img src={close} alt="Закрыть" width="30" /></button>
            {showPopup ?
              (
                <div className="grid items-center ">
                  <img src={comment} alt="Комментарии" loading="lazy" width="70" height="70" className="m-auto" />
                  <h3>Спасибо</h3>
                  <p>Ваш отзыв уже в пути и будет добавлен сразу после модерации</p>
                </div>
              )
              :
              (
                <div className="grid gap-y-4">
                  <h2 className="m-0">Как вам матрас <br/> {brand + ' ' + nameProduct}?</h2>
                  <img src={imgProduct} alt={nameProduct} width="300" className="image-product" />
                  <p className={`font-semibold 
                    ${errorForm && (starRating.motionIsolation === 0 || starRating.easeMovement === 0 || starRating.edgeSupport === 0 || starRating.temperatureControl === 0 || starRating.overallScore === 0 ? 'text-red-600':'')}`}>Поставьте оценки</p>
                  <Rating>
                    <div className="flex justify-between items-center">
                      <p className={starRating['motionIsolation'] > 0 ? 'active' : '' || (errorForm && starRating.motionIsolation === 0 ? 'text-red-600':'')}>Изоляция движения</p>
                      <div className="star-rating" onClick={(e) => handleStarClick(e, 'motionIsolation')} role="presentation">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index} className={`star ${starRating['motionIsolation'] > index ? 'active' : ''}`}>&#9733;</span>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className={starRating['easeMovement'] > 0 ? 'active' : '' || (errorForm && starRating.easeMovement === 0 ? 'text-red-600':'')}>Легкость передвижения</p>
                      <div className="star-rating" onClick={(e) => handleStarClick(e, 'easeMovement')} role="presentation">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index} className={`star ${starRating['easeMovement'] > index ? 'active' : ''}`}>&#9733;</span>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className={starRating['edgeSupport'] > 0 ? 'active' : '' || (errorForm && starRating.edgeSupport === 0 ? 'text-red-600':'')}>Поддержка краев</p>
                      <div className="star-rating" onClick={(e) => handleStarClick(e, 'edgeSupport')} role="presentation">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index} className={`star ${starRating['edgeSupport'] > index ? 'active' : ''}`}>&#9733;</span>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className={starRating['temperatureControl'] > 0 ? 'active' : '' || (errorForm && starRating.temperatureControl === 0 ? 'text-red-600':'')}>Контроль температуры</p>
                      <div className="star-rating" onClick={(e) => handleStarClick(e, 'temperatureControl')} role="presentation">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index} className={`star ${starRating['temperatureControl'] > index ? 'active' : ''}`}>&#9733;</span>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className={`font-semibold ${starRating['overallScore'] > 0 ? 'active' : ''} ${errorForm && starRating.overallScore === 0 ? 'text-red-600':''}`}>Общая оценка</p>
                      <div className="star-rating" onClick={(e) => handleStarClick(e, 'overallScore')} role="presentation">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index} className={`star ${starRating['overallScore'] > index ? 'active' : ''}`}>&#9733;</span>
                        ))}
                      </div>
                    </div>
                  </Rating>
                  <textarea
                    name="text"
                    value={commentData.text}
                    onChange={(e) => handleTextAreaChange(e)}
                    placeholder="Напишите отзыв"
                    className={`h-auto resize-none overflow-hidden ${errorForm && commentData.text.trim() === '' ? 'error' : ''}`}
                    rows="1"
                    type="text"
                  />
                  {errorForm && commentData.text.trim() === '' && <span className="text-sm text-red-600 text-left -mt-4">Заполните поле</span>}
                  <input type="text" name="name" maxlength="30" value={commentData.name} onChange={handleInputChange} placeholder="Ваше имя" className={errorForm && commentData.name.trim() === '' ? 'error' : ''} />
                  {errorForm && commentData.name.trim() === '' && <span className="text-sm text-red-600 text-left -mt-4">Заполните поле</span>}
                  
                  {uploadedImageUrl ? 
                    (<div className="relative w-fit flex">
                      <img src={uploadedImageUrl} alt="" loading="lazy" width="100" />
                      <button className="remove-file absolute -right-1 -top-1 rounded-full bg-zinc-500 flex items-center justify-center w-5 h-5" onClick={handleRemoveFile}>
                        <img src={closeWhite} alt="" loading="lazy" width="15" />
                      </button>
                    </div>)
                    :
                    (<div className="upload-img flex items-center justify-center gap-x-4">
                      <img src={upload} alt="Загрузка изображения" loading="lazy" width="30" height="30" />
                      <p>Добавить фото</p>
                      <input type="file" name="file" onChange={handleInputChange} accept="image/*" title=" " />
                    </div>)
                  }
                  
                  <button className="send-review" onClick={handleCommentSubmit}>Отправить</button>
                </div>
              )
            }
          </div>
        </CommentsForm>
      )}
    </>
  );
};

export default ReviewForm;
