import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { firestore } from "../../firebase.js"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import { Script } from "gatsby"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Share from "../components/share.js"
import Widget from "../components/market-widget.js"
import Faq from "../components/faq.js"
// import Otherpages from "../components/otherpages.js"
import Footer from "../components/footer.js"
import Author from "../components/author.js"
// import TableOfContent from "../components/table-of-content-product.js"
import ReviewForm from "../components/ReviewForm.js"

// Картинки
import height from "../images/icons/height.svg"
import weight from "../images/icons/weight.svg"
import maxload from "../images/icons/maxload.svg"
import typeMattress from "../images/icons/type.svg"
import wintersummer from "../images/icons/wintersummer.svg"

import price from "../images/icons/price.svg"
import garant from "../images/icons/garant.svg"
import delivery from "../images/icons/delivery.svg"
import vakuum from "../images/icons/vakuum.svg"
import chehol from "../images/icons/chehol.svg"
import like from "../images/icons/like.svg"
import dislike from "../images/icons/dislike.svg"
import comment from "../images/icons/comment.svg"

const CommentsList = styled("ul")`
	margin-top: 50px;
  	li {
    	margin-bottom: 30px;
    	.avatar {
    		min-width: 2.5rem;
    	}
  	}
  	#reply-comment {
  		//color: rgb(49, 99, 156);
  		width: fit-content;
  	}
  	.form-comment-response {
  		padding: 20px;
    	background: rgb(246, 249, 250);
	    border-radius: 16px;
	    gap: 20px;
	    @media(max-width: 767px) {
		    padding: 10px;
		}

	    input,textarea {
		    outline: 0;
		    border-radius: 15px;
		    padding: 10px;
		    font-size: 14px;
		    width: 100%;
		}
		.send-reply {
		    background-color: #4a90e2;
		    height: 45px;
		    border-radius: 30px;
		    font-size: 14px;
		    color: #ffffff;
		    padding: 0 30px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    font-family: "Montserrat", sans-serif;
		    transition: all 0.3s;
		    width: 23%;
		    &:hover {
		      	box-shadow: rgba(30, 48, 110, 0.3) 0px 10px 26px 0px;
		      	background-color: rgb(49, 99, 156);
		    }
		    @media(max-width:992px) {
		      	width: 40%;
		    }
		}
		.cancel-response-form {
			transition: all 0.3s;

			&:hover {
				color: #4a90e2;
			}
		}
  	}
`;

const SubCommentsList = styled("ul")`
	margin-top: 20px;
	padding-left: 50px;
  	li {
    	margin-bottom: 30px;
  	}
`;

const Popup = styled("div")`
  	z-index: 999;
  	background: rgba(0,0,0,.8);
  	width: 100%;
  	height: 100%;
  	position: fixed;
  	left: 0;
  	top: 0;

  	.popup-container {
	    position: relative;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    background: white;
	    padding: 50px 20px;
	    border-radius: 10px;
	    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
	    width: 30%;
	    display: grid;
	    text-align: center;
	    gap: 10px;

	    @media(max-width:992px) {
	      	width: 85%;
	    }
  	}

  	h3 {
   		margin: 0;
  	}

  	p {
	    width: 80%;
	    margin: auto;

	    @media(max-width:992px) {
	      	width: 100%;
	    }
  	}

  	img {
    	margin: auto;
  	}

  	button {
	    position: absolute;
	    right: 15px;
	    top: 15px;
	    font-weight: bold;
	    font-size: 20px;
	    line-height: 20px;
  	}
`;

const PopupImg = styled("div")`
	z-index: 999;
	background: rgba(0,0,0,.8);
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;

	.popup-container {
	    position: relative;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    align-items: center;
	    justify-content: center;
	    display: flex;
	    width: fit-content;

	    img {
	    	width: 65vh;
	    }
	}

	button {
	    position: absolute;
	    right: 15px;
	    top: 15px;
	    font-weight: bold;
	    font-size: 20px;
	    line-height: 20px;
	    color: #fff;
  	}
`;

const Rating = styled("div")`
  .star-rating .star {
    font-size: 24px;
    color: #d9d9d9;
  }

  .star-rating .star.active {
    color: #4a90e2;
  }
`;

export default function ProductMattress({data}) {  
	//const [array,setArray] = useState([])
  	const [open, setOpen] = useState(true)
  	const [responseName, setResponseName] = useState("");
  	const [responseText, setResponseText] = useState("");
  	const [showResponseForm, setShowResponseForm] = useState({});
  	const [showPopup, setShowPopup] = useState(false);
  	const [commentAvatarColors, setCommentAvatarColors] = useState([]);
  	const [subcommentAvatarColors, setSubcommentAvatarColors] = useState([]);
  	const [showPopupImage, setShowPopupImage] = useState(null);

  	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC'
	};
  	
	const modelMattress = data.allContentfulMattress.edges.map(({ node }) => ( node.name)) // Получаем название матраса

	const brand = data.allContentfulMattress.edges.map(({ node }) => ( node.brand.name)) // Получаем название бренда

	const brandLinkPage = data.allContentfulMattress.edges.map(({ node }) => ( node.brand.linkBrandPage)) // Получаем урл страницы бренда

	const imgProduct = data.allContentfulMattress.edges.map(({ node }) => ( node.imageHero.file.url )) // Получаем ссылку на картинку матраса

	// const otherArticles = data.allContentfulMattress.edges.map(({ node }, i) => ( node.otherArticles.map(pages => ( pages )) ))

	const faqs = data.allContentfulMattress.edges.map(({ node }) => ( node))
	const accordionData = [ // Создаем массив с пунктами FAQ
	  	{
	    	title: faqs[0].faqTitle1 && faqs[0].faqTitle1,
	    	content: faqs[0].faqText1 && faqs[0].faqText1.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle2 && faqs[0].faqTitle2,
	    	content: faqs[0].faqText2 && faqs[0].faqText2.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle3 && faqs[0].faqTitle3,
	    	content: faqs[0].faqText3 && faqs[0].faqText3.childMarkdownRemark.html,
	  	}
	]

	const slugPage = data.allContentfulMattress.edges.map(({ node }) => ( node.url))

  	const handleResponseSubmit = async (commentId) => {
	    const subcommentsRef = collection(firestore, "subreviews");

	    // Add a document to the 'subcomments' subcollection
	    await addDoc(subcommentsRef, {
	      	name: responseName,
	      	text: responseText,
	      	pageIdentifier: slugPage[0],
	      	parentId: commentId,
	      	timestamp: serverTimestamp(),
	      	show: false,
	    });

	    // Clear response form data
	    setResponseName("");
	    setResponseText("");

	    setShowResponseForm((prev) => ({ ...prev, [subcommentsRef.id]: false }));

	    setShowPopup(true);
  	};

  	const openResponseForm = (commentId) => {
	    setShowResponseForm((prev) => ({ ...prev, [commentId]: true }));
	};

	const closeResponseForm = (commentId) => {
	    setShowResponseForm((prev) => ({ ...prev, [commentId]: false  }));
	};

	const closePopup = () => {
    	setShowPopup(false);
  	};

  	const handleOverlayClick = (e) => {
	    if (e.target.classList.contains('overlay')) {
	      	closePopup();
	    }
  	};

	const subcomments = data.allSubreviews.edges.map(({ node }) => ( node))

	const getRandomColor = () => {
	    const letters = '0123456789ABCDEF';
	    let color = '#';
	    for (let i = 0; i < 6; i++) {
	      color += letters[Math.floor(Math.random() * 16)];
	    }
	    return color;
	};

  	useEffect(() => {
	    const initialColors = Array.from(
	      	{ length: data.allReviews.edges.length },
	      	() => getRandomColor()
	    );
	    setCommentAvatarColors(initialColors);
	}, [data]);

	useEffect(() => {
	    const initialColors = Array.from(
	      	{ length: data.allSubreviews.edges.length },
	      	() => getRandomColor()
	    );
	    setSubcommentAvatarColors(initialColors);
	}, [data]);

  	const showImg = (commentId) => {
    	setShowPopupImage(commentId);
  	};

  	const closePopupImage = () => {
    	setShowPopupImage(null);
  	};

  	const handleOverlayClickImg = (e) => {
	    if (e.target.classList.contains('overlay')) {
	      	closePopupImage();
	    }
  	};

  	const handleTextAreaChange = (e) => {
	    const textarea = e.target;
	    textarea.style.height = 'auto'; // Reset the height to auto to recalculate the scrollHeight
	    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
	    setResponseText(textarea.value)
	};

	const reviewsArr = data.allReviews.edges.map(({ node}, index ) => ( node ))

	return (
		<>	
			{data.allContentfulMattress.edges.map(({ node }) => (
				<div key={node.id}>
					<Seo title={node.title.replace('{year}', (new Date().getFullYear()))} description={node.description.replace('{year}', (new Date().getFullYear()))} />
					<Helmet>
						<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru/"},{"@type": "ListItem","position": 2,"name": "Матрасы ${brand[0]}","item": "https://sleepadvisor.ru/${brandLinkPage[0]}/"},{"@type": "ListItem","position": 3,"name": "Матрас ${node.brand.name} ${node.name}"}]}`}</script>
						<script type="application/ld+json">{`{"@context":"https://schema.org","@graph":[{"@type":"Article","mainEntityOfPage":{"@type":"WebPage","@id":"https://sleepadvisor.ru/${node.brand.linkBrandPage}/${node.url}/"},"headline":"Матрас ${node.brand.name} ${node.name}","description":"${node.description.replace('{year}', (new Date().getFullYear()))}","image":"${node.imageHero.file.url}","author":{"@type":"Person","name":"Оксана Федосова","knowsAbout":"Эксперт по матрасам и другим товарам для сна","url":"https://sleepadvisor.ru/author.jpg"},"publisher":{"@type":"Organization","name":"SleepAdvisor","logo":{"@type":"ImageObject","url":"https://sleepadvisor.ru/logo.svg"}},"datePublished":"${node.createdAt}","dateModified":"${node.updatedAt}","potentialAction":{"@type":"ReadAction","target":{"@type":"EntryPoint","urlTemplate":"https://sleepadvisor.ru/${node.brand.linkBrandPage}/${node.url}/"}}}]}`}</script>
						<script type="application/ld+json">{`{"@context": "http://schema.org","@type": "Review","itemReviewed": {"@type": "Product","name": "${node.name}","image": "","review": {"@type": "Review","reviewRating": {"@type": "Rating","ratingValue": ${node.rating},"bestRating": 5,"worstRating": 0},"author": {"@type": "Person","name": "Оксана Федосова"},"reviewBody": "${node.overallScore.overallScore}"}},"reviewRating": {"@type": "Rating","ratingValue": ${node.rating},"bestRating": 5,"worstRating": 0},"author": {"@type": "Person","name": "Оксана Федосова"},"reviewBody": "${node.overallScore.overallScore}"}`}</script>
					</Helmet>
				</div>
			))}
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item"><a href={`/`+brandLinkPage[0]+`/`}>{`Лучшие матрасы ` + brand[0]}</a></li>
					                <li className="breadcrumb-item active" aria-current="page">{modelMattress[0]}</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>{`Матрас ` + brand[0] + ` ` + modelMattress[0]}</h1>
				</div>
			</div>
			<section className="content content-product pb-8 max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
				<div className="container">
					{data.allContentfulMattress.edges.map(({ node }, i) => (
						<div key={node.id} className="about-product">
							<GatsbyImage image={getImage(node.imageHero)} alt={node.name} />
							{node.affilateLink && <div className="about-product__button"><a href={node.affilateLink} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','aff_card')}}>Показать цену</a></div>}
							{/*<TableOfContent />*/}
							<div className="table-of-contents-product mt-6">
						        <button className="table-of-contents-product__title" onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
						          	<span>
						            	<span className="line"></span>
						            	<span className="line"></span>
						            	<span className="line"></span>
						          	</span> 
						          	Быстрые ссылки
						        </button>
						        <ul className={open === true ? 'active' : false}>
						            <li key={Math.random()}>
						            	<button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#info');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Информация о продукте</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#layers');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Наполнитель</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#characteristics');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Характеристика матраса {node.name}</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#size');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Доступные размеры</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#garant');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Гарантия</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#overallScore');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Общая оценка</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#faq');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Вопрос–Ответ</button>
						            </li>
						            <li key={Math.random()}><button className="cursor-pointer" onClick={(e) => {
						              	e.preventDefault();
						              	const elementToView = document.querySelector('#reviews');
						              	window.scrollTo({top: elementToView.offsetTop-100});
						            	}}>Отзывы</button>
						            </li>
						        </ul>
						    </div>
							<div className="about-product__table">
								<div>
									<h2 id="info">Информация о продукте</h2>
									<div className="about-product__items">
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={height} alt="Высота" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Высота</p>
						            		<p>{node.height + ` см`}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={weight} alt="Вес" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Вес</p>
						            		<p>{`от ` + node.weight + ` кг`}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={maxload} alt="Нагрузка" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Нагрузка</p>
						            		<p>{node.load + ` кг`}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={price} alt="Цена" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Цена</p>
						            		{node.price === '₽' && <p className="price"><span>₽</span>₽₽₽₽</p>}
						            		{node.price === '₽₽' && <p className="price"><span>₽₽</span>₽₽₽</p>}
						            		{node.price === '₽₽₽' && <p className="price"><span>₽₽₽</span>₽₽</p>}
						            		{node.price === '₽₽₽₽' && <p className="price"><span>₽₽₽₽</span>₽</p>}
						            		{node.price === '₽₽₽₽₽' && <p className="price"><span>₽₽₽₽₽</span></p>}
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={garant} alt="Гарантия" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Гарантия</p>
						            		<p>{node.warranty}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={typeMattress} alt="Тип" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Тип</p>
						            		<p>{node.type}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={delivery} alt="Доставка" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Доставка</p>
						            		<p>{node.delivery}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={vakuum} alt="Скрутка" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Скрутка</p>
						            		<p>{node.vakuum === true ? 'Да' : 'Нет'}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={wintersummer} alt="Зима/Лето" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Зима/Лето</p>
						            		<p>{node.wintersummer === true ? 'Да' : 'Нет'}</p>
										</div>
										<div className="about-product__item">
						            		<div className="image">
						            			<img src={chehol} alt="Чехол" loading="lazy" width="70" height="70" />
						            		</div>
						            		<p className="title">Чехол</p>
						            		<p>{node.chehol}</p>
										</div>
									</div>
								</div>
								<div>
									<h3>Жесткость</h3>
									{node.firm2Side ? <>
										<div className="firmness">
											<p className="firmness__side">1 сторона</p>
											<div>
												<div className="firmness__scale">
													<div className="line"></div>
													<span className={node.firm1Side === 'Мягкий' ? 'soft active' : 'soft'}></span>
													<span className={node.firm1Side === 'Умеренно-мягкий' ? 'soft-middle active' : 'soft-middle'}></span>
													<span className={node.firm1Side === 'Средний' ? 'middle active' : 'middle'}></span>
													<span className={node.firm1Side === 'Умеренно-жесткий' ? 'soft-firm active' : 'soft-firm'}></span>
													<span className={node.firm1Side === 'Жесткий' ? 'firm active' : 'firm'}></span>
												</div>
												<div className="firmness__scale-text">
													<p className={node.firm1Side === 'Мягкий' ? 'active' : ''}>Мягкий</p>
													<p className={node.firm1Side === 'Умеренно-мягкий' ? 'active' : ''}>Умеренно-мягкий</p>
													<p className={node.firm1Side === 'Средний' ?'active' : ''}>Средний</p>
													<p className={node.firm1Side === 'Умеренно-жесткий' ?'active' : ''}>Умеренно-жесткий</p>
													<p className={node.firm1Side === 'Жесткий' ? 'active' : ''}>Жесткий</p>
												</div>
											</div>
										</div>
										<div className="firmness mt-10">
											<p className="firmness__side">2 сторона</p>
											<div>
												<div className="firmness__scale">
													<div className="line"></div>
													<span className={node.firm2Side === 'Мягкий' ? 'soft active' : 'soft'}></span>
													<span className={node.firm2Side === 'Умеренно-мягкий' ? 'soft-middle active' : 'soft-middle'}></span>
													<span className={node.firm2Side === 'Средний' ? 'middle active' : 'middle'}></span>
													<span className={node.firm2Side === 'Умеренно-жесткий' ? 'soft-firm active' : 'soft-firm'}></span>
													<span className={node.firm2Side === 'Жесткий' ? 'firm active' : 'firm'}></span>
												</div>
												<div className="firmness__scale-text">
													<p className={node.firm2Side === 'Мягкий' ? 'active' : ''}>Мягкий</p>
													<p className={node.firm2Side === 'Умеренно-мягкий' ? 'active' : ''}>Умеренно-мягкий</p>
													<p className={node.firm2Side === 'Средний' ?'active' : ''}>Средний</p>
													<p className={node.firm2Side === 'Умеренно-жесткий' ?'active' : ''}>Умеренно-жесткий</p>
													<p className={node.firm2Side === 'Жесткий' ? 'active' : ''}>Жесткий</p>
												</div>
											</div>
										</div>
									</> :
										<div className="firmness">
											<div>
												<div className="firmness__scale">
													<div className="line"></div>
													<span className={node.firm1Side === 'Мягкий' ? 'soft active' : 'soft'}></span>
													<span className={node.firm1Side === 'Умеренно-мягкий' ? 'soft-middle active' : 'soft-middle'}></span>
													<span className={node.firm1Side === 'Средний' ? 'middle active' : 'middle'}></span>
													<span className={node.firm1Side === 'Умеренно-жесткий' ? 'soft-firm active' : 'soft-firm'}></span>
													<span className={node.firm1Side === 'Жесткий' ? 'firm active' : 'firm'}></span>
												</div>
												<div className="firmness__scale-text">
													<p className={node.firm1Side === 'Мягкий' ? 'active' : ''}>Мягкий</p>
													<p className={node.firm1Side === 'Умеренно-мягкий' ? 'active' : ''}>Умеренно-мягкий</p>
													<p className={node.firm1Side === 'Средний' ?'active' : ''}>Средний</p>
													<p className={node.firm1Side === 'Умеренно-жесткий' ?'active' : ''}>Умеренно-жесткий</p>
													<p className={node.firm1Side === 'Жесткий' ? 'active' : ''}>Жесткий</p>
												</div>
											</div>
										</div>
									}
								</div>
								<div>
									<p className="about-product__raiting">Рейтинг SleepAdvisor <span>{node.rating}</span> /5</p>
								</div>
							</div>
							<br/>
							<Widget dataId={'marketWidget'+(i++)} marketId={node.marketId} model={node.brand ? (node.brand.name +' '+ node.name) : (node.name)} />
							<div className="mt-14 font-[merriweather]">
								<div dangerouslySetInnerHTML={{ __html: node.minitext.childMarkdownRemark.html }}/>
							</div>
							<div className="mt-14 about-product__rec">
								<div className="like">
									<figure>
										<img src={like} alt="Кому понравится матрас" loading="lazy" width="35" height="35" />
									</figure>
									<p className="title">Кому понравится матрас:</p>
									<div dangerouslySetInnerHTML={{ __html: node.like.childMarkdownRemark.html }}/>
								</div>
								<div className="nuances">
									<figure>
										<img src={dislike} alt="Кому понравится матрас" loading="lazy" width="30" height="30" />
									</figure>
									<p className="title">Нюансы матраса:</p>
									<div dangerouslySetInnerHTML={{ __html: node.nuances.childMarkdownRemark.html }}/>
								</div>
							</div>
							<div className="mt-14 about-product__filler font-[merriweather]">
								<h2 id="layers">Наполнитель</h2>
								<div className="filler-block">
									<div className="filler-block__layer-image">
										{node.filler.map((item, index) =>
											<GatsbyImage key={Math.random()} className={item.name.indexOf('Чехол') !== -1 ? 'chehol-top' : ''} style={{zIndex: index}} image={getImage(item.image)} alt={item.name} />
										)}
										<StaticImage className="chehol-down" src="../images/layer-mattress/chehol-down.jpg" alt="Чехол низ" placeholder="none" quality="100" />
									</div>
									<div className="filler-block__layer-text">
										{node.filler.map((item, index) =>
											<p key={Math.random()} className={item.name.indexOf('Чехол') !== -1  ? 'chehol-top' : ''}>{item.name}</p>
										)}
									</div>
								</div>
								<div dangerouslySetInnerHTML={{ __html: node.fillerText.childMarkdownRemark.html }}/>
							</div>
							<div className="mt-14 about-product__characteristics">
								<h2 id="characteristics">Характеристика матраса {node.name}</h2>
								<GatsbyImage className="image" image={getImage(node.imageCharacteristics)} alt={node.name} />
								{node.isolation !== null && 
									<div className="table">
										<div className="flex">
											<p>Изоляция движения</p>
											<div className="stars"><span style={{width: node.isolation*20+'%'}}></span></div>
										</div>
										<div className="flex">
											<p>Поддержка краев</p>
											<div className="stars"><span style={{width: node.edgeSupport*20+'%'}}></span></div>
										</div>
										<div className="flex">
											<p>Легкость передвижения</p>
											<div className="stars"><span style={{width: node.easeMovement*20+'%'}}></span></div>
										</div>
										<div className="flex">
											<p>Контроль температуры</p>
											<div className="stars"><span style={{width: node.temperatureControl*20+'%'}}></span></div>
										</div>
									</div>
								}
								{node.affilateLink && <div className="about-product__button middle"><a href={node.affilateLink} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','aff_card')}}>Показать цену</a></div>}
								<h3 className="no-m">Изоляция движения</h3>
								<div className="font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.isolationText.childMarkdownRemark.html }}/>
								<h3>Поддержка краев</h3>
								<div className="font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.edgeSupportText.childMarkdownRemark.html }}/>
								<h3>Легкость передвижения</h3>
								<div className="font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.easeMovementText.childMarkdownRemark.html }}/>
								<h3>Контроль температуры</h3>
								<div className="font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.temperatureControlText.childMarkdownRemark.html }}/>
							</div>
							<div className="mt-14 about-product__sizes">
								<h2 id="size">Доступные размеры</h2>
								<div className="table-size" dangerouslySetInnerHTML={{ __html: node.sizes.childMarkdownRemark.html }}/>
							</div>
							<div className="mt-14 font-[merriweather]">
								<h2 id="garant">Гарантия</h2>
								<div dangerouslySetInnerHTML={{ __html: node.guarantee.childMarkdownRemark.html }}/>
							</div>
							<div id="1" className="marketWidgetBlog" data-text={node.brand.name}></div>
							<div className="mt-14 about-product__score">
								<h2 id="overallScore">Общая оценка</h2>
								<div dangerouslySetInnerHTML={{ __html: node.overallScore.childMarkdownRemark.html }}/>
								<div className="score">
									<p>{node.rating}<span>из 5</span></p>
								</div>
								<div className="rating">
									<span className="line"></span>
									<div className="stars"><span style={{width: node.rating*20+'%'}}></span></div>
									<span className="line"></span>
								</div>
							</div>
						</div>
					))}
                	<Share />
                	<Author />
				</div>
			</section>
			{faqs[0].faqTitle1 && (<section className="faq max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<div className="container">
					<h2 id="faq">Вопрос–Ответ</h2>
					<div className="accordeon-custom" itemScope itemType="https://schema.org/FAQPage">
						<div className="accordeon-custom__items">
							{accordionData.map(({ title, content }) => (
								<Faq key={Math.random()} title={title} content={content} />
							))}
						</div>
					</div>
				</div>
			</section>)}
			{/*<Otherpages otherArticles={otherArticles[0]} titlePage="рейтинги" />*/}
			<section className="additionalResources py-8 max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<div className="container">
					<h3>Другие популярные статьи</h3>
					{data.allContentfulMattress.edges.map(({ node }) => (
						<div className="grid-style" key={Math.random()} dangerouslySetInnerHTML={{ __html: node.raitings.childMarkdownRemark.html }} />
					))}
				</div>
			</section>
			<section className="comments py-8 pb-16 max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<h2 id="reviews">Отзывы</h2>
				{reviewsArr.length === 0 ?
					(
						<div className="grid justify-center pt-4">
							<img src={comment} alt="Комментарии" loading="lazy" width="70" height="70" className="m-auto mb-2" />
							<h4>Отзывов пока нет</h4>
							<ReviewForm pageIdentifier={slugPage[0]} nameProduct={modelMattress[0]} imgProduct={imgProduct[0]} brand={brand[0]} reviewsLength="m-auto" />
						</div>
					)
					:
					(
					<>
					<ReviewForm pageIdentifier={slugPage[0]} nameProduct={modelMattress[0]} imgProduct={imgProduct[0]} brand={brand[0]} />
					<div id="comments">
						<CommentsList>
							{data.allReviews.edges.map(({ node}, index ) => (
				                <li key={node.id}>
					                <div className="flex gap-x-4">
						                <div className="avatar rounded-full w-10 h-10 flex items-center justify-center text-white text-xl capitalize" style={{ backgroundColor: commentAvatarColors[index] }}>{node.name.charAt(0)}</div>
						                <div className="grid gap-y-2 w-full">
							                <div className="flex gap-x-4 items-center">
						                  		<p className="font-semibold capitalize">{node.name}</p>
						                  		<Rating>
							                  		<div className="star-rating flex cursor-pointer">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star ${index < node.overallScore ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                  		</Rating>
						                  		<p className="text-slate-400 text-xs">{new Intl.DateTimeFormat('ru-RU', options).format(new Date(node.timestamp))}</p>
						                  	</div>
						                  	<Rating className="grid grid-cols-1 md:grid-cols-2 gap-x-20">
						                        <div className="flex gap-x-4 items-center justify-between">
							                  		<p className="text-xs">Изоляция движения</p>
							                  		<div className="star-rating flex">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star mr-1 ${index < node.motionIsolation ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                        </div>
						                        <div className="flex gap-x-4 items-center justify-between">
							                  		<p className="text-xs">Легкость передвижения</p>
							                  		<div className="star-rating flex">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star mr-1 ${index < node.easeMovement ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                        </div>
						                        <div className="flex gap-x-4 items-center justify-between">
							                  		<p className="text-xs">Поддержка краев</p>
							                  		<div className="star-rating flex">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star mr-1 ${index < node.edgeSupport ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                        </div>
						                        <div className="flex gap-x-4 items-center justify-between">
							                  		<p className="text-xs">Контроль температуры</p>
							                  		<div className="star-rating flex">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star mr-1 ${index < node.temperatureControl ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                        </div>
						                        {/*<div className="flex gap-x-4 items-center col-span-1">
							                  		<p className="text-xs">Общая оценка</p>
							                  		<div className="star-rating flex">
								                  		{Array.from({ length: 5 }).map((_, index) => (
								                        	<span key={index} className={`star ${node.overallScore > 0 ? 'active' : ''}`}>&#9733;</span>
								                        ))}
							                        </div>
						                        </div>*/}
						                  	</Rating>
					                  		<p className="font-[merriweather] text-sm leading-6">{node.text}</p>
					                  		<button onClick={() => showImg(node.id)} className="cursor-zoom-in w-1/4"><img src={node.file} alt={node.fileName} /></button>
					                  		{showPopupImage === node.id && (
						                  		<PopupImg className="overlay" onClick={handleOverlayClickImg}>
							                  		<div className="popup-container">
							                  			<img src={node.file} alt={node.fileName} />
						                  			</div>
						                  			<button onClick={closePopupImage}>x</button>
						                  		</PopupImg>
					                  		)}
					                  		<button id="reply-comment" className="text-sm text-slate-400" onClick={() => openResponseForm(node.id)}>Ответить</button>
					                  		{showResponseForm[node.id] && (
									            <div className="form-comment-response grid mt-4">
							                  		<input
									                  type="text"
									                  name={`response-name-${node.id}`}
									                  value={responseName}
									                  onChange={(e) => setResponseName(e.target.value)}
									                  placeholder="Имя"
									                />
									                <textarea
									                  name={`response-text-${node.id}`}
									                  value={responseText}
									                  onChange={(e) => handleTextAreaChange(e)}
									                  placeholder="Написать ответ..."
									                  className="h-auto resize-none overflow-hidden"
									                  rows="1"
									                />
									                <div className="flex justify-end gap-x-4">
										                <button className="cancel-response-form text-sm" onClick={() => closeResponseForm(node.id)}>Отмена</button>
										                <button className="send-reply" onClick={() => handleResponseSubmit(node.id)}>Отправить</button>
									                </div>
									            </div>
								            )}
					                  	</div>
						            </div>
						            <SubCommentsList>
						            	{subcomments.map((item, i) => (
						            		node.id === item.parentId && (
								            	<li>
								            		<div className="flex gap-x-4">
										                <div className="avatar rounded-full w-10 h-10 flex items-center justify-center text-white text-xl capitalize" style={{backgroundColor: subcommentAvatarColors[i]}}>{item.name.charAt(0)}</div>
										                <div className="grid gap-y-2  w-full">
											                <div className="flex gap-x-4 items-center">
										                  		<p className="font-semibold capitalize">{item.name}</p>
										                  		<p className="text-slate-400 text-xs">{new Intl.DateTimeFormat('ru-RU', options).format(new Date(item.timestamp))}</p>
										                  	</div>
									                  		<p className="font-[merriweather] text-sm leading-6">{item.text}</p>
									                  	</div>
										            </div>
								            	</li>
							            	)
						            	))}
						            </SubCommentsList>
				                </li>
							))}
						</CommentsList>
					</div>
					</>
					)
				}
			</section>
			{showPopup && (
		        <Popup onClick={handleOverlayClick} className="overlay">
		          	<div className="popup-container">
		            	<img src={comment} alt="Комментарии" loading="lazy" width="70" height="70" />
		            	<h3>Спасибо</h3>
		            	<p>Ваш комментарий уже в пути и будет добавлен сразу после модерации</p>
		            	<button onClick={closePopup}>x</button>
		          	</div>
		        </Popup>
		    )}

		    {/*Баннер мобильный. Низ*/}
	        <Script strategy="idle"
	          dangerouslySetInnerHTML={{
	            __html: `
	              (function (w) {
	                window.yaContextCb.push(()=>{
	                  Ya.Context.AdvManager.render({
	                    "blockId": "R-A-690338-11",
	                    "type": "floorAd"
	                  })
	                })
	              })(window);
	            `,
	          }}
	        />
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query PageProductMattress($slug: String!) {
    	allContentfulMattress(filter: { url: { eq: $slug } }) {
      		edges {
	        	node {
	          		id
    				name
    				affilateLink
    				marketId
    				brand {
        				__typename
        				... on ContentfulBrand {
        					id
    						name
    						linkBrandPage
        				}
    				}
	          		delivery
			        easeMovement
			        easeMovementText {
			          childMarkdownRemark {
			            html
			          }
			        }
			        edgeSupport
			        edgeSupportText {
			          childMarkdownRemark {
			            html
			          }
			        }
			        filler {
			        	__typename            			
			        	... on ContentfulFillerMattress {
            				id
            				name
            				image {
            					gatsbyImageData(
					              	layout: FIXED
					              	quality: 100
					              	width: 720
					            )
            				}
            			}
			        }
			        fillerText {
			          childMarkdownRemark {
			            html
			          }
			        }
			        firm1Side
			        firm2Side
			        firmness
			        height
			        isolation
			        load
			        name
			        price
			        rating
			        sizes {
			        	childMarkdownRemark {
				            html
				        }
			        }
			        temperatureControl
			        temperatureControlText {
			          childMarkdownRemark {
			            html
			          }
			        }
			        type
			        url
			        vakuum
			        weight
			        wintersummer
			        warranty
			        chehol
			        overallScore {
			        	overallScore
			          	childMarkdownRemark {
			            	html
			          	}
			        }
			        nuances {
			          	childMarkdownRemark {
			            	html
			          	}
			        }
			        minitext {
			          childMarkdownRemark {
			            html
			          }
			        }
			        like {
			          childMarkdownRemark {
			            html
			          }
			        }
			        isolationText {
			          childMarkdownRemark {
			            html
			          }
			        }
			        guarantee {
			          childMarkdownRemark {
			            html
			          }
			        }
	          		faqTitle1
			        faqTitle2
			        faqTitle3
			        faqText1 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText2 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText3 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        imageHero {
			        	file {
			        		url
			        	}
			            gatsbyImageData(
			              	quality: 100
			              	width: 1000
			              	placeholder: NONE
			            )
			        }
			        imageCharacteristics {
			        	gatsbyImageData(
			              	layout: FULL_WIDTH
			              	quality: 100
			              	width: 720
			            )
			        }
			        raitings {
			        	childMarkdownRemark {
		            		html
		        		}
			        }
			        updatedAt
	          		createdAt
	          		title
	          		description
	        	}
	      	}
    	}
    	allReviews(sort: {fields: timestamp, order: DESC}, filter: { pageIdentifier: { eq: $slug }, show: {ne: false} }) {
		    edges {
		      	node {
		      		id
			        name
			        pageIdentifier
			        text
			        timestamp
			        file
			        fileName
			        easeMovement
			        edgeSupport
			        motionIsolation
			        overallScore
			        temperatureControl
		      	}
		    }
		}
		allSubreviews(filter: { pageIdentifier: { eq: $slug }, show: {ne: false} }) {
		    edges {
		      	node {
		      		id
			        name
			        pageIdentifier
			        text
			        timestamp
			        parentId
		      	}
		    }
		}
  	}
`