import React from "react"
// import { Helmet } from "react-helmet"


export default function marketWidget({dataId, marketId, model}) {

	return (
  	<>
    	{/*<Helmet>
      	{typeof window !== 'undefined' && <script async src="https://aflt.market.yandex.ru/widget/script/api" type="text/javascript"></script>}
        {typeof window !== 'undefined' && <script async type="text/javascript" >{`
          {
            (function (w) {
                    function start() {
                      w.removeEventListener("YaMarketAffiliateLoad", start);
                      document.querySelectorAll('.marketWidget').forEach((el, i) => {
                          const id = el.getAttribute('data-id');
                          const model = el.getAttribute('data-model');
                          const widgetID = el.getAttribute('id');
                          let text = '';

                          id === null && (text = model)

                          w.YaMarketAffiliate.createWidget({
                            type:"offers",
                            containerId:widgetID,
                            fallback:false,
                            params:{
                              clid:2409477,
                              metrikaCounterId:62038639,
                              searchModelIds:[id],
                              searchText:text,
                              searchInStock:false,
                              themeId:10 
                            } 
                          });
                      })
                    }
                    w.YaMarketAffiliate
                  ? start()
                  : w.addEventListener("YaMarketAffiliateLoad", start);
                })(window);
              }
        `}</script>}
    	</Helmet>*/}
    	<div id={dataId} className="marketWidget" data-id={marketId} data-model={model}></div>
  	</>
	)
}

